<template>
  <div
    class="contactus"
    :style="{
      height: pageWidth < 700 ? 'calc(100vh - 333px)' : 'calc(100vh - 310px)',
    }"
  >
    <el-backtop :visibility-height="20"> </el-backtop>
    <div class="website_header">
      <img
        src="@/assets/logo2.png"
        :style="{ display: pageWidth < 700 ? 'none' : 'flex' }"
        alt=""
      />
      <h1
        :style="{ fontSize: pageWidth < 700 ? '32px' : '64px' }"
        style="margin-bottom: 52px"
      >
        联系我们
      </h1>
    </div>

    <div class="contact_content">
      <div class="contact_con_t">
        <h3>电话</h3>
        <p>400-168-5218</p>
      </div>
      <div
        class="contact_con_b"
        :style="{ display: pageWidth < 700 ? 'block' : 'flex' }"
      >
        <div style="margin-right: 50px">
          <h3>邮箱</h3>
          <p>cpleitb@gmail.com</p>
        </div>
        <div :style="{ marginTop: pageWidth < 700 ? '20px' : '' }">
          <h3>地址</h3>
          <p>
            Flat 1602,16/F,Lucky Centre, No.165-171 Wan Chai Road,Wan Chai ,Hong
            Kong
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";
export default {
  data() {
    return {
      pageWidth: 0,
    };
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },
};
</script>

<style></style>
